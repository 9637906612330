import { FunctionComponent } from "react"
import { useStyle } from "../../hooks/use-style"
import { lightTheme, darkTheme } from "../../services/theme"
import React from "react"

export type Props = {
    loading: boolean
}

const Loader: FunctionComponent<Props> = ({ loading: isLoading }) => {
    const css = useStyle(theme => ({
        color:
            theme === "light"
                ? lightTheme.color.text.primary
                : darkTheme.color.text.primary,
    }))

    return <></>
    /*
    return !isLoading ? null : (
        <>
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>

            <style jsx>{`
                .spinner {
                    text-align: center;
                }
                .spinner > div {
                    width: 1em;
                    height: 1em;
                    background-color: ${css.color};
                    transition: 200ms background-color;
                    border-radius: 100%;
                    display: inline-block;
                    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out
                        both;
                    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                }
                .spinner > div:not(:first-child) {
                    margin-left: 0.5em;
                }
                .spinner > div:not(:last-child) {
                    margin-right: 0.5em;
                }
                .spinner .bounce1 {
                    -webkit-animation-delay: -0.32s;
                    animation-delay: -0.32s;
                }
                .spinner .bounce2 {
                    -webkit-animation-delay: -0.16s;
                    animation-delay: -0.16s;
                }
                @-webkit-keyframes sk-bouncedelay {
                    0%,
                    80%,
                    100% {
                        -webkit-transform: scale(0);
                    }
                    40% {
                        -webkit-transform: scale(1);
                    }
                }
                @keyframes sk-bouncedelay {
                    0%,
                    80%,
                    100% {
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                    40% {
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            `}</style>
        </>
    )*/
}

export default Loader
