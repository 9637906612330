import {
    DetailedHTMLProps,
    ButtonHTMLAttributes,
    forwardRef,
    useMemo,
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconLookup } from "@fortawesome/fontawesome-svg-core"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { lightTheme, darkTheme } from "../../../services/theme"
import { useTheme } from "../../../hooks/use-theme"
import React from "react"

export type Props = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    icon?: IconLookup
    variant?: Variant
    size?: "lg"
    fullWidth?: boolean
    loading?: boolean
}

type Variant = "primary" | "secondary" | "border" | "active" | "transparent"

const Button = forwardRef<HTMLButtonElement, Props>(
    (
        {
            icon,
            variant = "primary",
            size,
            fullWidth: isFullWidth = false,
            loading: isLoading = false,
            children,
            ...buttonProps
        },
        ref
    ) => {
        const { theme } = useTheme()
        const css = useMemo(
            () => ({
                backgroundColors: {
                    primary:
                        theme === "light"
                            ? lightTheme.color.background.primary
                            : darkTheme.color.background.primary,
                    secondary:
                        theme === "light"
                            ? lightTheme.color.background.secondary
                            : darkTheme.color.background.secondary,
                    border:
                        theme === "light"
                            ? lightTheme.color.border
                            : darkTheme.color.border,
                    active:
                        theme === "light"
                            ? lightTheme.color.active
                            : darkTheme.color.active,
                    transparent: "transparent",
                } as { [key in Variant]: string },
                textColor:
                    variant === "active" || theme === "light"
                        ? lightTheme.color.text.primary
                        : darkTheme.color.text.primary,
                borderColor:
                    variant === "transparent"
                        ? "transparent"
                        : theme === "light"
                        ? lightTheme.color.border
                        : darkTheme.color.border,
                inactiveButtonBackgroundColor:
                    theme === "light"
                        ? lightTheme.color.border
                        : darkTheme.color.border,
                inactiveButtonTextColor: darkTheme.color.text.secondary,
            }),
            [theme, variant]
        )
        const padding = size === "lg" ? "0.7em 1.1em" : "0.4em 0.8em"

        icon = isLoading ? faSpinnerThird : icon

        return (
            <button ref={ref} {...buttonProps}>
                {icon && (
                    <span>
                        <FontAwesomeIcon icon={icon} spin={isLoading} />
                    </span>
                )}

                {!isLoading && children}

                <style jsx>{`
                    button {
                        border-radius: 0.3em;
                        font-size: 14px;
                        font-weight: ${size === "lg" ? "bold" : "normal"};
                        border: none;
                        position: relative;
                        padding: ${padding};
                        background-color: ${css.backgroundColors[variant]};
                        border: 1px solid ${css.borderColor};
                        color: ${css.textColor};
                        transition: 200ms color, 200ms background-color,
                            200ms border-color;
                        display: inline-flex;
                        width: ${isFullWidth ? "100%" : "auto"};
                        justify-content: center;
                        align-items: center;
                    }
                    button:disabled {
                        background-color: ${css.inactiveButtonBackgroundColor};
                        color: ${css.inactiveButtonTextColor};
                    }
                    span {
                        margin-right: ${children ? " 0.3em" : 0};
                    }
                `}</style>
            </button>
        )
    }
)

export default Button
